import React, {useState, useEffect} from 'react';
import './App.css';

function App() {
    let params = new URLSearchParams(window.location.search);

    let searchedName = params.get('name');
    let leaderboard = params.get('leaderboard');

    let [name, setName] = useState("");

    return (<>
            <div className="App">
                <h1 style={{textAlign: 'center', cursor: "pointer"}} onClick={() => window.location.search = ''}>Debate
                    Spyder</h1>
                <p style={{textAlign: 'center'}}>
                    <button onClick={() => window.location.search = "?leaderboard=pf"}>PF Leaderboard</button>
                    {" "}
                    <button onClick={() => window.location.search = "?leaderboard=ld"}>LD Leaderboard</button>
                </p>
                <div className="flex">
                    <input onChange={(ev) => setName(ev.currentTarget.value)} type="text"
                           placeholder={searchedName || "Name (e.g. 'John Doe')"} style={{flexGrow: 1}} value={name}/>
                    <button onClick={() => {
                        let x = new URLSearchParams(window.location.search);
                        x.set('name', name);
                        window.location.search = '?name=' + name;
                    }} style={{marginLeft: 5}}>Lookup
                    </button>
                </div>
                {searchedName && <Rounds searchedName={searchedName}/>}
                {leaderboard && <Leaderboard leaderboard={leaderboard}/>}
            </div>
            <footer>made by samyok</footer>
        </>
    );
}

function Rounds({searchedName}) {
    const [xhr, setXhr] = useState([]);
    const [debateOnly, setDebateOnly] = useState(true)

    useEffect(() => {
        fetch('/competitor/' + searchedName)
            .then(r => r.json())
            .then(setXhr)
    }, [searchedName])
    let {data, stats} = xhr;

    if (!data) return <p>Loading...</p>;
    if (!data.length) return <p>Name not found.</p>;
    let participatedIn = new Set(), partners = new Set();
    let wins = 0, total = 0, score = 0, scoreable = 0;

    function returnScoreFromString(s) {
        if (!s) return null;
        if (+s) return +s;
        s = s.split("\n");
        for (let i = 0; i < s.length; i++)
            if (s[i].includes(searchedName.split(" ").reverse()[0]))
                return +s[i].split(" ").reverse()[0]

        return null;
    }

    let lastname = searchedName.split(" ").reverse()[0];
    data.forEach(ballot => {
        partners.add(...ballot.names.filter(nm => !nm.toUpperCase().includes(lastname.toUpperCase())))
        participatedIn.add(ballot.event.name.replace('Public Forum', 'PF').replace(/Lincoln-?Douglas/g, 'LD'));
        if (ballot.result) {
            if (ballot.result === "W") wins++;
            total++;
        }
        if (ballot.points && returnScoreFromString(ballot.points)) {
            score += returnScoreFromString(ballot.points);
            scoreable++;
        }
    })
    participatedIn = Array.from(participatedIn);
    if (participatedIn.length > 1) participatedIn[0] = "and " + participatedIn[0];
    participatedIn = participatedIn.reverse().join(", ");
    const colors = {
        default: '#fae3ac',
        W: '#c3f192',
        L: '#ffaaaa'
    }
    return <div className="rounds" style={{display: searchedName ? 'block' : 'none'}}>
        <h3>{searchedName}, {data[0]?.school}</h3>
        <p style={{fontSize: 10}}>Participates in {participatedIn}</p>
        <p style={{fontSize: 20}}>Winrate: {wins}/{total} = {((10000 * wins / total) >> 0) / 100}%</p>
        <p style={{marginLeft: 20}}>Aff: {stats.aff[0]}/{stats.aff[0] + stats.aff[1]} = {(stats.aff[0] * 10000 / (stats.aff[0] + stats.aff[1]) >> 0) / 100}%</p>
        <p style={{marginLeft: 20}}>Neg: {stats.neg[0]}/{stats.neg[0] + stats.neg[1]} = {(stats.neg[0] * 10000 / (stats.neg[0] + stats.neg[1]) >> 0) / 100}%</p>
        <p style={{fontSize: 15}}>Average speaks: {(100 * score / scoreable >> 0) / 100}</p>
        <p style={{fontSize: 15}}>Partners: {Array.from(partners).filter(a => !!a).map(a => (<><a
            href={`/?name=${a}`}>{a}</a>{" "}</>))}</p>
        <button
            onClick={() => setDebateOnly(pv => !pv)}>{debateOnly ? 'Include non-debate events' : 'Inlcude only debate events'}</button>
        <table>
            <tbody>{data.filter(ballot => !debateOnly || ballot.result).reverse().map((ballot, i, arr) => {
                let eloDiff = ballot.eloAfterTheRound ? ballot.eloAfterTheRound[searchedName] : 0;
                if(eloDiff && i > 0){
                    // we keep goign to find the last eloAfterTheRound;
                    for (let j = i - 1; j >= 0; j--) {
                        if(arr[j].eloAfterTheRound){
                            eloDiff -= arr[j].eloAfterTheRound[searchedName]
                            break;
                        }
                    }
                }
                return  <tr
                    style={{
                        backgroundColor: colors[ballot.result] ? colors[ballot.result] : colors.default
                    }}
                    key={JSON.stringify(ballot)}>
                    <td><a target='_blank' rel="noreferrer"
                           href={`https://www.tabroom.com/index/tourn/postings/entry_record.mhtml?tourn_id=${ballot.tournment.id}&entry_id=${ballot.id}`}>{ballot.names.map(e => e.split(" ").reverse()[0]).join(" & ")}</a>
                    </td>
                    <td>{ballot.roundName}</td>
                    <td>{ballot.side}</td>
                    <td>{Object.keys(ballot.eloAfterTheRound || {}).filter(name => !ballot.names.includes(name)).map(name =>
                        <><a href={`?name=${name}`}>{name}</a>{" "}</>)}</td>
                    <td><a target='_blank' rel="noreferrer"
                           href={`https://www.tabroom.com/index/tourn/postings/entry_record.mhtml?tourn_id=${ballot.tournment.id}&entry_id=${ballot.vs.id}`}>{ballot.vs.name}</a>
                    </td>
                    <td>{ballot.division}</td>
                    <td>{ballot.result}</td>
                    <td>{returnScoreFromString(ballot.points)}</td>
                    <td><a target='_blank' rel="noreferrer"
                           href={`https://www.tabroom.com/index/tourn/postings/judge.mhtml?tourn_id=${ballot.tournment.id}&judge_id=${ballot.judge.id}`}>{ballot.judge.name}</a>
                    </td>
                    <td><a target='_blank' rel="noreferrer"
                           href={`https://www.tabroom.com/index/tourn/index.mhtml?tourn_id=${ballot.tournment.id}`}>{ballot.tournment.name}</a>
                    </td>
                    <td>{ballot.tournment.date}</td>
                    <td>{ballot.eloAfterTheRound ? (ballot.eloAfterTheRound[searchedName]*1e3 >> 0)/1e3 : "-"}</td>
                </tr>
            })}</tbody>
        </table>

    </div>

}

function Leaderboard({leaderboard}) {
    const [data, setData] = useState([])
    const [sort, setSort] = useState(0);
    const [hideSouthern, setHideSouthern] = useState(false);
    const [filtered, setFiltered] = useState(true);

    const northernSchools = [
        'Brookings',
        'Aberdeen Central High School',
        'Watertown High School',
        'Milbank High School',
        'Lennox High School',
        'Mitchell High School',
        'Huron High School'
    ]
    const sorts = [
        {name: 'percentage', value: 'percent'},
        {name: 'V Wins', value: 'vwins'},
        {name: 'overall wins', value: 'wins'},
        {name: 'V percentage', value: 'vpercent'},
        {name: 'Elo', value: 'elo'}
    ]
    useEffect(() => {
        let filteredURI = filtered ? 'leaderboard' : 'all';
        fetch(`/${filteredURI}/${leaderboard}/${sorts[sort].value}`)
            .then(r => r.json())
            .then(setData)
    }, [leaderboard, sort, filtered])

    return (<>
        <button onClick={() => setSort(pv => (pv + 1) % sorts.length)}>Sorting
            by {sorts[sort].name}</button>{" "}
        <button onClick={() => setHideSouthern(pv => !pv)}>Toggle Southern Teams</button>{" "}
        <button onClick={() => setFiltered(pv => !pv)}>{filtered ? 'Include' : 'Exclude'} Novice Teams</button>
        <table>
            <thead>
            <th>Rank</th>
            <th>W/L</th>
            <th>%Win</th>
            <th>Elo</th>
            <th>Name</th>
            <th>School</th>
            <th>V</th>
            <th>JV</th>
            <th>N</th>
            <th>Aff</th>
            <th>Neg</th>
            </thead>
            <tbody>
            {data.map((person, index) => <tr key={JSON.stringify(person)}
                                             className={hideSouthern && !northernSchools.includes(person.school) ? 'strike' : ''}>
                <td>{index + 1}</td>
                <td>{person.wins}/{person.total - person.wins}</td>
                <td>{(person.percent * 10000 >> 0) / 100}%</td>
                <td>{(person.elo * 1e5 >> 0)/1e5}</td>
                <td><a href={"/?name=" + person.name}>{person.name}</a></td>
                <td>{person.school}</td>
                <td className='center'>{person.winDivisons.v}/{person.total - person.novice - person.jv}</td>
                <td className='center'>{person.winDivisons.jv}/{person.jv}</td>
                <td className='center'>{person.winDivisons.n}/{person.novice}</td>
                <td className='center'>{person.aff[0]}/{person.aff[0] + person.aff[1]}</td>
                <td className='center'>{person.neg[0]}/{person.neg[0] + person.neg[1]}</td>
            </tr>)}
            </tbody>
        </table>

    </>);
}

export default App;
